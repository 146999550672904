<template>
    <main>
        <header
            class="page-header page-header-compact page-header-light border-bottom bg-white mb-4"
        >
            <div class="container-fluid">
                <div class="page-header-content">
                    <div class="row align-items-center justify-content-between pt-3">
                        <div class="col-auto mb-3">
                            <h1 class="page-header-title">
                                <div class="page-header-icon"></div>
                                Factura de Eventos
                            </h1>
                        </div>
                    </div>
                </div>
            </div>
        </header>
        <!-- Main page content-->
        <div class="container-fluid mt-4">
            <div class="row">
                <div class="form-group col-3 offset-3">
                    <input type="text" class="form-control form-control-sm" v-model="numero_factura">
                </div>
                <div class="form-group col-3">
                    <button class="btn btn-success btn-sm shadow-sm" @click="getInvoice()">Buscar</button>
                </div>
            </div>
            <div class="row">
                <div class="col-10 offset-1">
                    <div class="card">
                        <div class="card-body">
                            <div class="row">
                                <div class="col-12 table-responsive">
                                    <table class="table table-sm">
                                        <tr>
                                            <th>Numero de Factura</th>
                                            <td>{{factura.prefijo}}{{factura.numero}}</td>
                                            <th>Usuario</th>
                                            <td>{{usuario}}</td>
                                        </tr>
                                        <tr>
                                            <th>Eps</th>
                                            <td>{{eps}}</td>
                                            <th>Contrato</th>
                                            <td>{{contrato}}</td>
                                        </tr>
                                        <tr>
                                            <th>Fecha Atencion</th>
                                            <td>{{factura.fecha}}</td>
                                        </tr>
                                    </table>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-12 table-responsive">
                                    <table class="table table-sm">
                                        <thead>
                                            <tr>
                                                <td colspan="5">Servicios</td>
                                            </tr>
                                            <tr>
                                                <td>Codigo</td>
                                                <td>Servicio</td>
                                                <td>Cantidad</td>
                                                <td>Valor Unitario</td>
                                                <td>Total</td>
                                            </tr>
                                        </thead>
                                        <tr v-for="(detalle,i) in factura.detalles" v-bind:key="i">
                                            <td>{{detalle.servicio.codigo}}</td>
                                            <td>{{detalle.servicio.descripcion}}</td>
                                            <td>{{detalle.cantidad}}</td>
                                            <td>{{detalle.valor}}</td>
                                            <td>{{detalle.valor * detalle.cantidad}}</td>
                                        </tr>
                                        <tr>
                                            <td colspan="5">Valor Total {{factura.valor_neto | cop_currency}}</td>
                                        </tr>
                                    </table>
                                </div>
                            </div>
                            <div class="row" v-if="factura.id_service !== null || factura.id_service !== 0">
                                <div class="col-12">
                                    <button class="btn btn-warning" @click="sendInvoiceEps()">Enviar Factura Electronica</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </main>
</template>

<script>

import axios from 'axios';
import facturaService from "../../../services/facturaService";
import Swal from "sweetalert2/src/sweetalert2.js";
import {isEmpty} from 'lodash';

export default {
    data (){
        return {
            factura : {},
            numero_factura : '',
        }
    },
    methods : {
        async getInvoice() {
            try{
                this.LoaderSpinnerShow();
                let url = `/api/factura/invoice/${this.numero_factura}`;
                let response = await axios.get(url);
                this.LoaderSpinnerHide();
                this.factura = response.data;
            }catch (e){
                console.error(e);
                this.LoaderSpinnerHide();
            }

        },
        async sendInvoiceEps() {
            let id_factura = this.factura.id;
            try {
                Swal.fire({
                    title: "Esta seguro de enviar está factura electronicamente ?",
                    showCancelButton: true,
                    icon: "question",
                    confirmButtonText: "Sí, estoy seguro",
                    cancelButtonText: "Cancelar",
                    showLoaderOnConfirm: true,
                    preConfirm: () => {
                        return facturaService
                            .sendInvoice(id_factura)
                            .then((response) => {
                                return response.data;
                            })
                            .catch((error) => {
                                Swal.showValidationMessage(`Error: ${error.statusText}`);
                            });
                    },
                    allowOutsideClick: () => !Swal.isLoading(),
                }).then((result) => {
                    if (result.isConfirmed) {
                        if (result.value.State === "Exitosa") {
                            Swal.fire({
                                text: `Factura electronica enviada con exito`,
                                icon: "success",
                            });
                        } else {
                            let message = "";
                            result.value.ErrorList.forEach((element) => {
                                message += `\n ${element}`;
                            });
                            Swal.showValidationMessage(`Error: ${message}`);
                        }
                    }
                });
            } catch (error) {
                console.error(error);
            }
        },
    },
    computed: {
        usuario : function(){
            if(isEmpty(this.factura)){
                return;
            }

            return `${this.factura.usuario.documento} ${this.factura.usuario.nombre_1} ${this.factura.usuario.nombre_2} ${this.factura.usuario.apellido_1} ${this.factura.usuario.apellido_2}`;
        },
        eps : function(){
            if(isEmpty(this.factura)){
                return;
            }

            return `${this.factura.eps.IDEPS} ${this.factura.eps.DESEPS}`;
        },
        contrato : function(){
            if(isEmpty(this.factura)){
                return;
            }
            return `${this.factura.contrato.codigo} ${this.factura.contrato.descripcion}`;
        },
    },
}
</script>

<style scoped>

</style>
